@font-face {
    font-family: 'MontSerrat';
    src: url('./fonts/Montserrat.ttf');
}

body {
    font-family: 'MontSerrat';
}

.imgDiv {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.typeIcons {
    width: 15px;
}

.basicSpacing {
    letter-spacing: 4px;
}

.bg-main {
    background: #004a3f
}

.text-main {
    color: #e5ae9c;
}

#title {
    letter-spacing: 4px;
    font-weight: 600;
}

#textWall {
    font-size: small;
}

@media only screen and (min-width: 600px) {
    #grazie {
        display: none;
    }
}